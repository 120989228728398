import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dropzone from 'react-dropzone'
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import Tooltip from '@mui/material/Tooltip';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Link from '@mui/material/Link';
import { Chart } from "react-google-charts";
import FolderIcon from '@mui/icons-material/Folder';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WrapTextIcon from '@mui/icons-material/WrapText';
import GroupsIcon from '@mui/icons-material/Groups';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

import ProjectSelecter from '../../Components/ProjectSelecter'

export default class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            banks: [],
            employees: [],
            users: [],

            id_project: null,
        }
        this.projectIdChange = this.projectIdChange.bind(this)
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Dashboard`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/projects', config)
        .then(response => {    
            axios.get('/api/v1/banks', config)            
            .then(respons => {    
                axios.get('/api/v1/employees', config)
                .then(respon => {    
                    axios.get('/api/v1/users', config)
                    .then(respo => {  
                        this.setState({ 
                            projects: response.data,
                            banks: respons.data,
                            employees: respon.data,
                            users: respo.data,
                            loading: false
                        });
                    }).catch(error => {
                        this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
                    });
                }).catch(error => {
                    this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
                });
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });  
        });   
    }

    projectIdChange(id){ this.setState({id_project: id}) }

    render(){

        const {
            projects,
            banks,
            employees,
            users,
            id_project
        } = this.state

        const dataProjets = () => {
            let item = [["Projets", "Prévisions", "Entrées", "Dépenses"],]

            if(projects.length > 0){
                projects.map((answer) => {
                    let name = answer.name
                    let budget = answer.budget.amount
                    let lineBudgets = answer.budget.lineBudgets
                    let lineBudgetEntries = answer.budget.lineBudgetEntries
                    let expense = 0
                    let entry = 0
                    lineBudgets.map((elem) => {
                        expense = expense + (elem.unitCost * elem.quantity)
                    })
                    lineBudgetEntries.map((elem) => {
                        entry = entry + elem.amount
                    })
                    let a = [name, budget, entry, expense]
                    item = item.concat([a],)
                })
            }else{
                item = item.concat([["N/A", 0, 0, 0]],)
            }

            return item
        }

        const optionProjets = {
            chart: {
              title: "L'ensemble des projets",
              subtitle: "Prévisions, entrées et dépenses",
            },
        }

        let ProjectName = "Tous les projets"  
        let lineBudgets = []  
        let budget = 0
        let expense = 0        
        if(id_project !== null){
            ProjectName = projects.filter(c => c["id"] === id_project)[0].name
            budget = projects.filter(c => c["id"] === id_project)[0].budget.amount
            lineBudgets = projects.filter(c => c["id"] === id_project)[0].budget.lineBudgets
            lineBudgets.map((elem) => {
                expense = expense + (elem.unitCost * elem.quantity)
            })
        }else{
            projects.map((answer) => {
                budget = budget + answer.budget.amount
                lineBudgets = answer.budget.lineBudgets
                lineBudgets.map((elem) => {
                    expense = expense + (elem.unitCost * elem.quantity)
                })
            })
        }

        const dataProjet = [
            ["Prévisions", "Dépenses"],
            ["Prévisions", budget],
            ["Dépenses", expense]
        ]
          
        const optionProjet = {
            title: "Prévisions et dépenses",
            is3D: true,
        }

        const dataForProject = () => {
            let item = [["Projet", "Prévision", "Entrées", "dépenses", "reste de la prévision"],]

            let project = projects

            if(id_project !== null){
                project = projects.filter(c => c["id"] === id_project)           

                project.map((answer) => {
                    let name = answer.name
                    let budget = answer.budget.amount

                    let lineBudgets = answer.budget.lineBudgets
                    let lineBudgetEntries = answer.budget.lineBudgetEntries
                    let expense = 0
                    let entry = 0
                    lineBudgets.map((elem) => {
                        expense = expense + (elem.unitCost * elem.quantity)
                    })
                    lineBudgetEntries.map((elem) => {
                        entry = entry + elem.amount
                    })
                    let a = [name, budget, entry, expense, budget - entry]
                    item = item.concat([a],)
                })
            }else{
                // let a = 
                item = item.concat([["N/A", 0, 0, 0, 0]],)
            }

            return item
        } 

        const optionForProject = {
            chart: {
              title: "Prévision, entrées, dépenses, et reste de la prévision du projet",
              subtitle: "Prévision, entrées, dépense, reste de la prévision",
            },
        }

        return(
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4}>
                    <Grid item xs={12} sm={12} md={9} />
                        
                    <Grid item xs={12} sm={12} md={3} >
                        <ProjectSelecter  onChange={this.projectIdChange} />
                    </Grid>

                    {id_project === null && <React.Fragment>
                        {/* First Line */}
                        <Grid item xs={12} sm={6} md={3} > 
                            <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2, display: 'flex', justifyContent:'space-between'}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize: 24}}
                                    >
                                        {projects.length > 9 ? projects.length : "0" + projects.length}
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize: 16 }}>Projets</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                    <FolderIcon sx={{fontSize: 36, color:"#306bff"}} /> 
                                </Box>
                            </Card>
                        </Grid>   

                        <Grid item xs={12} sm={6} md={3} > 
                            <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2, display: 'flex', justifyContent:'space-between'}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize: 24}}
                                    >
                                        {banks.length > 9 ? banks.length : "0" + banks.length}
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize: 16 }}>Banques</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                    <AccountBalanceIcon sx={{fontSize: 36, color:"#306bff"}} /> 
                                </Box>
                            </Card>
                        </Grid> 

                        <Grid item xs={12} sm={6} md={3} > 
                            <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2, display: 'flex', justifyContent:'space-between'}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize: 24}}
                                    >
                                        {employees.length > 9 ? employees.length : "0" + employees.length}
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize: 16 }}>Employés</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                    <GroupsIcon sx={{fontSize: 36, color:"#306bff"}} /> 
                                </Box>
                            </Card>
                        </Grid>  

                        <Grid item xs={12} sm={6} md={3} > 
                            <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2, display: 'flex', justifyContent:'space-between'}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize: 24}}
                                    >
                                        {users.length > 9 ? users.length : "0" + users.length}
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize: 16 }}>Utilisateurs</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                    <PeopleAltIcon sx={{fontSize: 36, color:"#306bff"}} /> 
                                </Box>
                            </Card>
                        </Grid>                      

                        {/* Second Line  */}
                        <Grid item xs={12} sm={12} md={12} >
                            <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2}}>
                                <Chart
                                    chartType="Bar"
                                    width="100%"
                                    height="200px"
                                    data={dataProjets()}
                                    options={optionProjets}
                                />
                            </Card>
                        </Grid>    
                    </React.Fragment>}

                    

                    {/* Thierd line */}
                    <Grid item xs={12} sm={6} md={4} > 
                        <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2, display: 'flex', justifyContent:'space-between'}}>
                            <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', justifyContent:"center" }}>
                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"black", fontWeight: 600, fontSize: 24}}
                                >
                                    {ProjectName}
                                </Typography>
                            </Box>

                            <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                <FolderIcon sx={{fontSize: 36, color:"#306bff"}} /> 
                            </Box>   
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} > 
                        <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2, display: 'flex', justifyContent:'space-between'}}>
                            <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"black", fontWeight: 600, fontSize: 24}}
                                >
                                    {budget.toLocaleString()}
                                </Typography>

                                <Typography sx={{color:'#46505b', fontSize: 16 }}>Prévisions</Typography>
                            </Box>

                            <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                <CurrencyExchangeIcon sx={{fontSize: 36, color:"#306bff"}} /> 
                            </Box>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} > 
                        <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2, display: 'flex', justifyContent:'space-between'}}>
                            <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"black", fontWeight: 600, fontSize: 24}}
                                >
                                    {expense.toLocaleString()}
                                </Typography>

                                <Typography sx={{color:'#46505b', fontSize: 16 }}>Dépenses</Typography>
                            </Box>

                            <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                <CreditScoreIcon sx={{fontSize: 36, color:"#306bff"}} /> 
                            </Box>
                        </Card>
                    </Grid>

                    {/* fourth line */}
                    <Grid item xs={12} sm={12} md={5} >
                        <Card elevation={0}  sx={{height:300, p:2, backgroundColor:'#ffffff', borderRadius:2}}>
                            <Chart
                                chartType="PieChart"
                                data={dataProjet}
                                options={optionProjet}
                                width={"100%"}
                                height={"260px"}
                            />
                        </Card>
                    </Grid>  

                    <Grid item xs={12} sm={12} md={7} >
                        <Card elevation={0}  sx={{ p:2, backgroundColor:'#ffffff', borderRadius:2}}>
                            <Chart
                                chartType="Bar"
                                width="100%"
                                height="260px"
                                data={dataForProject()}
                                options={optionForProject}
                            />  
                        </Card>
                    </Grid>

                    {/* five line */}
                    {/* 
                        <Grid item xs={12} sm={12} md={7} >
                            <Card elevation={0}  sx={{height:300, p:2, backgroundColor:'#ffffff', borderRadius:2}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left',}}>
                                    <Box sx= {{ display: 'flex', justifyContent:'space-between' }}>
                                        <Typography 
                                            component="h1" 
                                            variant="h1" 
                                            sx={{color:"black", fontWeight: 600, fontSize: 16}}
                                        >
                                            Budget et dépenses par tranche
                                        </Typography>

                                        <Button
                                            sx={{height:40, borderRadius:8, borderColor:'gray', borderWidth:1, border:' 1px solid', p:2 }}
                                            endIcon={<ExpandMoreIcon />}
                                            //onClick={this.handleClick}
                                        >
                                            1è tranche
                                        </Button>
                                    </Box>

                                    <Chart
                                        chartType="PieChart"
                                        data={dataRubric}
                                        options={optionRubric}
                                        width={"100%"}
                                        height={"260px"}
                                    />
                                </Box>                            
                            </Card>
                        </Grid> 
                    */}

                    {/* <Grid item xs={12} sm={12} md={5} >
                        <Card elevation={0}  sx={{height:300, p:2, backgroundColor:'#ffffff', borderRadius:2}}>
                            <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left',}}>
                                <Box sx= {{ display: 'flex', justifyContent:'space-between' }}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize: 16}}
                                    >
                                        Ligne budgétaire
                                    </Typography>

                                    <Button
                                        sx={{height:40, borderRadius:8, borderColor:'gray', borderWidth:1, border:' 1px solid', p:2 }}
                                        endIcon={<ExpandMoreIcon />}
                                        ///onClick={this.handleClick2}
                                    >
                                        Collecte des plantes
                                    </Button>
                                </Box>

                                <Chart
                                    chartType="PieChart"
                                    data={dataSubrubric}
                                    options={optionSubrubric}
                                    width={"100%"}
                                    height={"260px"}
                                />    
                            </Box>
                        </Card>
                    </Grid> */}
                </Grid>
            </React.Fragment>
        )

    }

}