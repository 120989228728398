import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

export default class Profil extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            id_user: null,
            roleId:'',
            password: "",
            passwordc:"",

            showPassword: false,
            showPasswordc: false,

            loading: true,
            isDid: false,
            isNotDit: false,
            message:""
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Profil`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const id = JSON.parse(localStorage.getItem("ID"))
        axios.get('/api/v1/users/' + id, config)
        .then(response => {            
            this.setState({ 
                name: response.data.name,
                email: response.data.email,
                roleId: response.data.role.id,
                id_user: id,
                loading: false
            });

        }).catch(error => {
            // this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            this.setState({ loading: false });
        }); 
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ message:"Les deux mots de passe ne sont pas identiques", isNotDit: true, edit:false })
        }else{
            this.setState({loading: true})   
            const bodyParameters = {
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                roleId: this.state.roleId,
            }
            const tokenJson = localStorage.getItem("TOKEN");
            const token = JSON.parse(tokenJson);        
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }
            axios.put( 
                '/api/v1/users/' + this.state.id_user,
                bodyParameters,
                config
            ).then(response => {                     
                this.setState({ message:"Profil modifié avec succès", isDid: true, loading: false});
                this.componentDidMount()     
                localStorage.setItem("EMAIL", JSON.stringify(response.data.email));
                localStorage.setItem("NAME", JSON.stringify(response.data.name));
                this.props.onChange()      
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            });
        }          

    }

    render(){

        const {
            name,
            email,
            password,
            passwordc,
            showPassword,
            showPasswordc,
            loading,
            isDid,
            isNotDit,
            message
        } = this.state

        return(
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={12} display="flex" direction="column" justifyContent="center" alignItems="center" >
                        <Avatar 
                            sx={{
                                boxShadow: 12, 
                                color: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                                backgroundColor: "primary.main",
                                width: 80, height: 80
                            }} 
                            elevation={10}
                        >
                            <span style={{fontSize:40}}>{name.charAt(0)}</span>
                        </Avatar>

                        <h4 style={{marginTop:16}}>Bienvenue {name}</h4>

                        <p style={{marginTop: 16, color:"gray"}}>Gérez vos informations, ainsi que la confidentialité et la sécurité de vos données.</p>            
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} />

                    <Grid item xs={12} sm={12} md={6}>
                        <Card elevation={6}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:2}}>
                            <form onSubmit={this.handleSubmit} >
                                <Grid sx={{ flexGrow: 1 }} container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom complet"
                                            name="name"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Email"
                                            name="email"
                                            style={{ width: '100%' }}
                                            value={email}
                                            onChange={this.handleChange}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            name="password"
                                            label="Nouveau mot de passe"
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            autoComplete="current-password"
                                            placeholder="Admin2022$"
                                            onChange={this.handleChange}
                                            style={{ width: '100%' }}
                                            size="small"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => this.setState({ showPassword:! showPassword})} 
                                                        >
                                                            {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                        </IconButton>
                                                    </InputAdornment>                                        
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            name="passwordc"
                                            label="Mot de passe confirmation"
                                            type={showPasswordc ? 'text' : 'password'}
                                            value={passwordc}
                                            autoComplete="current-password"
                                            placeholder="Admin2022$"
                                            onChange={this.handleChange}
                                            style={{ width: '100%' }}
                                            size="small"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                        >
                                                            {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                        </IconButton>
                                                    </InputAdornment>                                        
                                            }}
                                        />
                                    </Grid>
                                    

                                </Grid>

                                <Button  
                                    type="submit" 
                                    fullWidth
                                    variant="contained" 
                                    sx={{
                                        mt:3,
                                        color:"white", 
                                        textTransform: 'none', 
                                        fontSize:16, 
                                        height:40, 
                                        backgroundColor:'#888da0',  
                                        borderRadius:0, 
                                        ':hover':{backgroundColor:'#306bff'} 
                                    }}
                                >
                                    Enregistrer
                                </Button>   
                            </form>
                        </Card>
                    </Grid>
                </Grid>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )
    }

}